import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ModalVideo from 'react-modal-video';
import SectionTitle from '../../components/common/SectionTitle';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
const VideoSection = () => {
    const [isOpen, setOpen] = useState(false);
    const { t } = useTranslation();
    // This code sets the videoId state based on the current language using the i18n library
    const currentLanguage = i18n.resolvedLanguage;
    // Initialize the videoId state with the default videoId french.
    const [videoId, setVideoId] = useState('IYrA6dW9cMA');
    useEffect(() => {
        switch (currentLanguage) {
            case 'es':
                // set videoId for Spanis
                setVideoId('g33kHyF2DM8')
                break;
            case 'de':
                // set videoId for German
                setVideoId('Bg0PRyQxHXM')
                break;
            case 'it':
                // set videoId for Italian
                setVideoId('DCo9nQ9R95g')
                break;
            case 'nl':
                // set videoId for Dutch
                setVideoId('H5pRW1CFuPw')
                break;
            case 'ar':
                // set videoId for Arabic
                setVideoId('ZT41HDSop38')
                break;
            default:
                setVideoId("IYrA6dW9cMA")
        }

    }, [currentLanguage])

    return (
        <section className='feature-section'>
            <div className='container-fluid'>
                <div className='feature-container mx-xl-5 position-relative bg-dark ptb-120 rounded-custom'>
                    <img
                        loading='lazy'
                        role='presentation'
                        src='assets/img/client/fiamane_client_video.jpg'
                        alt='feature-bg'
                        className='feature-bg-mockup position-absolute w-100 h-100 left-0 right-0 top-0 bottom-0 rounded-custom'
                    />
                    <div className='container'>
                        <div className='feature-bg-img-content position-relative row align-items-center'>
                            <div className='col-12 col-lg-6'>
                                <SectionTitle
                                    title={t('pages.fiamaneClient.videoSection.title')}
                                    description={t('pages.fiamaneClient.videoSection.text')}
                                />
                                <ul
                                    className='list-unstyled d-flex gap-4'
                                    data-aos='fade-up'
                                >

                                    <li>
                                        <Link to="//play.google.com/store/apps/details?id=com.fiamane.client" target="_blank">
                                            <img
                                                src='assets/img/footer/google_play_store_logo.png'
                                                width='120'
                                                alt='playstore'
                                                className='img-fluid m-auto rounded'
                                            />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="//apps.apple.com/app/id1643690653" target="_blank">
                                            <img
                                                src='assets/img/footer/apple_store_logo.png'
                                                width='120'
                                                alt='playstore'
                                                className='img-fluid m-auto rounded'
                                            />
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className='col-12 col-lg-6'>
                                <ModalVideo
                                    channel='youtube'
                                    autoplay
                                    isOpen={isOpen}
                                    videoId={videoId}
                                    onClose={() => setOpen(false)}
                                />
                                <Link
                                    to='#'
                                    className='text-decoration-none d-inline-flex align-items-center watch-now-btn ms-lg-3 ms-md-3 mt-3 mt-lg-0 '
                                    onClick={() => setOpen(true)}
                                >
                                    <i style={{
                                        margin: "0 4px 0 4px"
                                    }} className='fas fa-play'></i> {t('pages.fiamaneClient.videoSection.regarderLaVideo')}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default VideoSection;

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const HeroThree = () => {
  const { t } = useTranslation();
  const currentLanguage = i18n.resolvedLanguage;

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const handleResize = () => setScreenWidth(window.innerWidth);
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [])
  return (
    <section
      className='hero-section pt-100 pb-60'
      style={{
        background:
          "url('assets/img/shape/dot-dot-wave-shape.svg')no-repeat bottom center",
      }}
    >
      <div className='container'>
        <div className='row align-items-lg-center justify-content-between'>
          <div className='col-lg-5 mb-7 mb-lg-0'>
            <div data-aos='fade-up'>

              <h1 className={currentLanguage === 'ar' ? "" : "fw-bold"}>{t('pages.fiamanePro.heroHeader.title')}</h1>
              <p className='lead'>{t('pages.fiamanePro.heroHeader.bodyText')}</p>


            </div>
            <div className='hero-subscribe-form-wrap pt-4 position-relative m-auto m-xl-0 d-block d-lg-block d-xl-block'>
              <Link to='/request-demo' className='btn btn-primary'>
                {t('pages.fiamanePro.requestADemo')}
              </Link>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='pr-lg-4 position-relative' data-aos='fade-up'>
              <div className='position-relative mx-auto'>
                {screenWidth < 767 ?
                  <picture>
                    <source
                      type='image/webp'
                      srcSet='assets/img/entreprise/fiamane-international-shipping-and-delivery-management-software-mobile-webp.webp'
                    />
                    <img
                      loading='lazy'
                      className='position-relative z-2 w-120 h-auto heroHeaderImg'
                      src='assets/img/entreprise/fiamane-international-shipping-and-delivery-management-software-mobile.png'
                      alt={t('alt.heroHeaderProImg')}
                    />
                  </picture>
                  :
                  <picture>
                    <source
                      type='image/webp'
                      srcSet='assets/img/entreprise/fiamane_site_entreprise_hero_section-webp.webp'
                    />
                    <img
                      loading='lazy'
                      className='position-relative z-2 w-120 h-auto heroHeaderImg'
                      src='assets/img/entreprise/fiamane-international-shipping-and-delivery-management-software.png'
                      alt={t('alt.heroHeaderProImg')}
                    />
                  </picture>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroThree;

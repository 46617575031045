import React from 'react';
import { useTranslation } from 'react-i18next';

const TrackingProcess = () => {

  const {t} = useTranslation();
  
  return (
    <section className='work-process ptb-60'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-md-10 col-lg-6'>
            <div className='section-heading text-center'>
              <h4 className='h5 text-primary'>{t('pages.fiamaneClient.trackingProcess.smallTitle')}</h4>
              <h2>{t('pages.fiamaneClient.trackingProcess.title')}</h2>
              <p>
              {t('pages.fiamaneClient.trackingProcess.text')}
              </p>
            </div>
          </div>
        </div>
        <div className='row d-flex align-items-center'>
          <div className='col-md-6 col-lg-3' style={{
            minHeight : "362px"
          }}>
            <div className='process-card text-center px-4 py-lg-5 py-4 rounded-custom shadow-hover mb-2 mb-lg-0'>
              <div className='process-icon border border-light bg-custom-light rounded-custom p-3'>
                <span className='h2 mb-0 text-primary fw-bold'>1</span>
              </div>
              <h3 className='h5'>  {t('pages.fiamaneClient.trackingProcess.stepOneTitle')}</h3>
              <p className='mb-0'>
              {t('pages.fiamaneClient.trackingProcess.stepOneText')} </p>
            </div>
          </div>
          <div className='dots-line first'></div>
          <div className='col-md-6 col-lg-3' style={{
            minHeight : "362px"
          }}>
            <div className='process-card text-center px-4 py-lg-5 py-3 rounded-custom shadow-hover mb-2 mb-lg-0'>
              <div className='process-icon border border-light bg-custom-light rounded-custom p-3'>
                <span className='h2 mb-0 text-primary fw-bold'>2</span>
              </div>
              <h3 className='h5'>  {t('pages.fiamaneClient.trackingProcess.stepTwoTitle')}</h3>
              <p className='mb-0'>
              {t('pages.fiamaneClient.trackingProcess.stepTwoText')}
              </p>
            </div>
          </div>
          <div className='dots-line first'></div>
          <div className='col-md-6 col-lg-3' style={{
            minHeight : "362px"
          }}>
            <div className='process-card text-center px-4 py-lg-5 py-4 rounded-custom shadow-hover mb-2 mb-lg-0 mb-md-0'>
              <div className='process-icon border border-light bg-custom-light rounded-custom p-3'>
                <span className='h2 mb-0 text-primary fw-bold'>3</span>
              </div>
              <h3 className='h5'>  {t('pages.fiamaneClient.trackingProcess.stepThreeTitle')}</h3>
              <p className='mb-0'>
              {t('pages.fiamaneClient.trackingProcess.stepThreeText')}
              </p>
            </div>
          </div>
          <div className='dots-line first'></div>
          <div className='col-md-6 col-lg-3' style={{
            minHeight : "362px"
          }}>
            <div className='process-card text-center px-4 py-lg-5 py-4 rounded-custom shadow-hover mb-0 mb-lg-0 mb-md-0'>
              <div className='process-icon border border-light bg-custom-light rounded-custom p-4'>
                <span className='h2 mb-0 text-primary fw-bold'>4</span>
              </div>
              <h3 className='h5'>  {t('pages.fiamaneClient.trackingProcess.stepFourTitle')}</h3>
              <p className='mb-0'>
              {t('pages.fiamaneClient.trackingProcess.stepFourText')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TrackingProcess;

import React, { useEffect } from 'react'
import Routers from './routers'

//bootstrap
import "bootstrap/dist/js/bootstrap.bundle";

//Modal Video
import "react-modal-video/scss/modal-video.scss";



function App() {

  useEffect(() => {
    const storedDirection = localStorage.getItem('direction');
    if (storedDirection === 'rtl') {
      document.body.style.direction = 'rtl';
      document.documentElement.setAttribute('lang', 'ar');
    } else {
      document.body.style.direction = 'ltr';
      document.documentElement.setAttribute('lang', 'fr');
    }
  }, []);
  return (
    <>
      <Routers />
    </>
  )
}

export default App
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';

const FeatureImgContentFour = () => {
    const { t } = useTranslation();
    const [hasAnimated, setHasAnimated] = useState(false);

    return (
        <section className='why-choose-us ptb-120'>
            <div className='container'>
                <div className='row justify-content-lg-between align-items-center'>

                    <div className='col-lg-6 col-12 order-lg-0'>
                        <div className='feature-img-holder mt-4 mt-lg-0 mt-xl-0'>
                            <picture>
                                <source
                                    type='image/webp'
                                    srcSet='assets/img/entreprise/parcel-cross-customs-webp.webp'
                                />
                                <img
                                    loading='lazy'
                                    src='assets/img/entreprise/parcel-cross-customs.png'
                                    className='img-fluid'
                                    alt={t('alt.featureThreeProImg')}
                                />
                            </picture>
                        </div>
                    </div>
                    <AnimatePresence>
                        <motion.div
                            whileInView={!hasAnimated ? { opacity: 1, x: -50 } : ""}
                            transition={{ duration: 0.8 }}
                            onAnimationComplete={() => setHasAnimated(true)}
                            className='col-lg-5 col-12 order-lg-1'
                        >
                            <div className='why-choose-content'>
                                <div className='icon-box rounded-custom bg-primary shadow-sm d-inline-block'>
                                    <i className='fal fa-shield-check fa-2x text-white'></i>
                                </div>
                                <h2>{t('pages.fiamanePro.featureThree.title')}</h2>
                                <div>
                                    <p>{t('pages.fiamanePro.featureThree.bodyText1')}</p>
                                    <p>{t('pages.fiamanePro.featureThree.bodyText2')}</p>
                                </div>
                                <Link
                                    to='/features-details' state={{ section: "link-3" }}
                                    className='read-more-link text-decoration-none'
                                >
                                    {t('pages.fiamanePro.linkToMoreDetails')}<i className='far fa-arrow-right ms-2'></i>
                                </Link>
                            </div>
                        </motion.div>
                    </AnimatePresence>
                </div>
            </div>
        </section>
    );
};

export default FeatureImgContentFour;

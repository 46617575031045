import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import LogoFiamane from '../../imags/logo/fiamaneLogo.svg';

const OffCanvasMenu = () => {
  return (
    <>
      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasWithBackdrop"
      >
        <div className="offcanvas-header d-flex align-items-center mt-4 m-4">
          {/* <Link
            to="/"
            className="d-flex align-items-center mb-md-0 text-decoration-none"
          >
            <img
              src={LogoFiamane}
              alt="logo"
              className="img-fluid ps-4"
            />
          </Link> */}
          <button
            type="button"
            className="close-btn text-danger"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            <i className="far fa-close"></i>
          </button>
        </div>
        <div className="offcanvas-body">

          <ul className="nav col-12 col-md-auto justify-content-center main-menu">
            <li className="nav-link">
              <a href="https://platforme.fiamane.com/"
                className=""
                target='_blank'
                rel="noreferrer"
              >
                <Trans i18nKey="navbar.page_1"></Trans>
              </a>
            </li>
            <li>
              <Link to="/entreprise" className="nav-link">
                <Trans i18nKey="navbar.page_2">Fiamane Pro</Trans>
              </Link>
            </li>
            <li>
              <Link to="/client" className="nav-link">
                <Trans i18nKey="navbar.page_4">Fiamane Client</Trans>
              </Link>
            </li>
            <li>
              <Link to="/pricing" className="nav-link">
                <Trans i18nKey="navbar.page_3">Tarification</Trans>
              </Link>
            </li>
            <li className="nav-item dropdown d-flex">
              <a
                className="nav-link dropdown-toggle"
                href="/#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <Trans i18nKey="navbar.plus">Plus</Trans>
              </a>
              <div className="dropdown-menu border-0 rounded-custom shadow py-0 bg-white">
                <div className="dropdown-grid rounded-custom width-full">
                  <div className="dropdown-grid-item">
                    <Link to="/contact-us" className="dropdown-link px-0">
                      <span className="me-2">
                        <i className="far fa-address-book"></i>
                      </span>
                      <Trans i18nKey="navbar.page_5">Nous contacter</Trans>
                    </Link>

                    <Link to="/help-center" className="dropdown-link">
                      <span className="me-2">
                        <i className="far fa-life-ring"></i>
                      </span>
                      <Trans i18nKey="navbar.page_6">Tutorials</Trans>
                    </Link>
                    <Link to="/request-demo" className="dropdown-link">
                      <span className="me-2">
                        <i className="far fa-laptop-code"></i>
                      </span>
                      <Trans i18nKey="navbar.page_7">Demander une démo</Trans>
                    </Link>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <div className="action-btns mt-4 ps-3 d-flex flex-column justify-content-start align-items-start gap-2">
            {/* <a href="//fiamane-preview.vercel.app/fr/recherche" className="btn border-primary w-50">
              La platforme
            </a> */}
            <Link to="/tracking" className="btn btn-primary w-50">
              <Trans i18nKey="navbar.page_8">Suivre un colis</Trans>
            </Link>

            {/* <div className='w-25 align-self-start'>
              <LanguageDropdown />
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default OffCanvasMenu;

import React from 'react';
import { Link } from 'react-router-dom';

const FooterSocial = () => {
  return (
    <>
      <footer className='footer-section'>
        {/* <!--footer bottom start--> */}
        <div className='footer-bottom bg-dark text-white py-4'>
          <div className='container'>
            <div className='row justify-content-between align-items-center'>
              <div className='col-md-7 col-lg-7'>
                <div className='copyright-text'>
                  <p className='mb-lg-0 mb-md-0'>
                    &copy; Fiamane | Tous droits réservés
                  </p>
                </div>
              </div>
              <div className='col-md-4 col-lg-4'>
                <div className='footer-single-col text-start text-lg-end text-md-end'>
                  <ul className='list-unstyled list-inline footer-social-list mb-0'>
                    <li className='list-inline-item'>
                      <a href='//www.facebook.com/Fiamaneapp' rel='noreferrer' target="_blank" >
                        <i className='fab fa-facebook-f'></i>
                      </a>
                    </li>
                    <li className='list-inline-item'>
                      <a href='//www.instagram.com/fiamane_app/' rel='noreferrer' target="_blank">
                        <i className='fab fa-instagram'></i>
                      </a>
                    </li>
                    <li className='list-inline-item'>
                      <a href='//www.youtube.com/@fiamane_app' rel='noreferrer' target="_blank">
                        <i className='fab fa-youtube'></i>
                      </a>
                    </li>
                    <li className='list-inline-item'>
                      <a href='//https://ma.linkedin.com/company/fiamane' rel='noreferrer'  target="_blank">
                        <i className='fab fa-linkedin'></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

            </div>
          </div>
        </div>
        {/* <!--footer bottom end--> */}
      </footer>
    </>
  );
};

export default FooterSocial;

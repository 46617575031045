import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence, } from 'framer-motion';

const FeatureImg = () => {
    const { t } = useTranslation();
    const [hasAnimated, setHasAnimated] = useState(false);


    return (
        <section className='image-feature pt-60 pb-120 bg-light'>
            <div className='container'>
                <div className='row justify-content-between align-items-center'>
                    <AnimatePresence>
                        <motion.div
                            whileInView={!hasAnimated ? { opacity: 1, x: 50 } : ""}
                            transition={{ duration: 0.8 }}
                            onAnimationComplete={() => setHasAnimated(true)}
                            className='col-lg-5 col-12 order-lg-0'
                        >
                            <div className='feature-img-content'>
                                <div className='icon-box rounded-custom bg-dark shadow-sm d-inline-block'>
                                    <i className='fa-solid fa-list-check fa-2x text-white'></i>
                                </div>
                                <h2>{t('pages.fiamanePro.featureTwo.title')}</h2>
                                <div>
                                    <p>{t('pages.fiamanePro.featureTwo.bodyText1')}</p>
                                    <p>{t('pages.fiamanePro.featureTwo.bodyText2')}</p>
                                </div>

                                <Link
                                    to='/features-details' state={{ section: "link-2" }}
                                    className='read-more-link text-decoration-none d-block mt-4'
                                >
                                    {t('pages.fiamanePro.linkToMoreDetails')} <i className='far fa-arrow-right ms-2'></i>
                                </Link>
                            </div>
                        </motion.div>
                    </AnimatePresence>
                    <div className='col-lg-6 col-12'>
                        <div className='feature-img-holder mt-4 mt-lg-0 mt-xl-0'>
                        <picture>
                                <source
                                type='image/webp'
                                srcSet='assets/img/entreprise/dashboard-interface-of-fiamane-webp.webp'
                                />
                            <img
                                loading='lazy'
                                src='assets/img/entreprise/dashboard-interface-of-fiamane.png'
                                className='img-fluid'
                                alt={t('alt.featureTwoProImg')}
                            />
                            </picture>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FeatureImg;

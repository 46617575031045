import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route, Navigate, HashRouter } from 'react-router-dom';
import ScrollToTop from '../components/common/ScrollToTop';
import FiamanePro from '../pages/fiamanePro/index';
import Pricing from '../pages/pricing/index';
import Client from '../pages/fiamaneClient/index';
import LoadingSpinner from '../components/others/LoadingSpinner';

// import Tracking from '../pages/tracking';
import RequestDemo from '../pages/request-demo/index';
const Tracking = lazy(() => import('../pages/tracking'));
const Contact = lazy(() => import('../pages/contact/index'));
const HelpCenter = lazy(() => import('../pages/tutos/index'));
const ServicesDetails = lazy(() => import('../pages/features-details/index'));
const GeneralCondition = lazy(() => import('../pages/general-conditions/index'));
const PrivacyPolicies = lazy(() => import('../pages/privacy-policies/index'));
const Feedback = lazy(() => import('../pages/Feeback/Feedback'));


function index() {

 
  return (
    <HashRouter>
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<FiamanePro />} />
        <Route exact path="/pro" element={<FiamanePro />} />
        <Route exact path="/pricing" element={<Pricing />} />
        <Route export path="/client" element={<Client />} />
        <Route exact path="/contact-us"
          element={<Suspense fallback={<LoadingSpinner />}>
            <Contact />
          </Suspense>}
        />
        <Route exact path="/help-center"
          element={<Suspense fallback={<LoadingSpinner />}>
            <HelpCenter />
          </Suspense>}
        />
        <Route exact path="/request-demo"
          element={<Suspense fallback={<LoadingSpinner />}>
            <RequestDemo />
          </Suspense>}
        />
        <Route exact path="/cgv"
          element={<Suspense fallback={<LoadingSpinner />}>
            <GeneralCondition />
          </Suspense>}
        />
        <Route exact path="/pdc"
          element={<Suspense fallback={<LoadingSpinner />}>
            <PrivacyPolicies />
          </Suspense>}
        />
        <Route exact path="/feedback"
          element={<Suspense fallback={<LoadingSpinner />}>
            <Feedback />
          </Suspense>}
        />
        <Route exact path="/features-details"
          element={<Suspense fallback={<LoadingSpinner />}>
            <ServicesDetails />
          </Suspense>}
        />
        <Route exact path="tracking"
          element={<Suspense fallback={<LoadingSpinner />}>
            <Tracking />
          </Suspense>}
        />
        <Route exact path="*" element={<Navigate to="/" />} />
      </Routes>
    </HashRouter>
  );
}

export default index;

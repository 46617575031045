import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';


export default function ArgumentOne() {
    const { t } = useTranslation();

    /** function to change the hero picture
   *  in function of the language one picture for arabic and an other illustration for the other languages */

    // Get the current language
    const currentLanguage = i18n.resolvedLanguage;

    // declare a variable for the img src and initilaize to the src of the illustration for rest of the langauges
    const [imgSrc, setImgSrc] = useState("assets/img/client/nfo-on-package-shipment-process.png");

    // test for the exception 'arabic' and change the img src to the arabic version
    useEffect(() => {
      
        switch (currentLanguage) {
            case 'ar':
                setImgSrc('assets/img/client/nfo-on-package-shipment-process-ar.png');
                break;
            case 'de':
                setImgSrc('assets/img/client/nfo-on-package-shipment-process-de.png');
                break;
            case 'es':
                setImgSrc('assets/img/client/nfo-on-package-shipment-process-es.png');
                break;
            case 'it':
                setImgSrc('assets/img/client/nfo-on-package-shipment-process-it.png');
                break;
            case 'nl':
                setImgSrc('assets/img/client/nfo-on-package-shipment-process-nl.png');
                break;
            default:
                setImgSrc('assets/img/client/nfo-on-package-shipment-process.png')
        }

        // a cleanup function to reset the img src to intial state
        const resetImg = () => {
            setImgSrc("assets/img/client/nfo-on-package-shipment-process.png");
        };

        return () => {
            resetImg();
        };

    }, [currentLanguage, imgSrc])
    return (
        <section className="payment-feature-img pt-40 pb-60">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div
                            className="payment-feature-img-right text-center position-relative mt-5 mt-lg-0">
                            <div className="payment-feature-mockup position-relative">
                                <img
                                    src={imgSrc}
                                    className="img-fluid"
                                    alt={`${t('alt.FeatureOneClientImg')}`}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="payment-feature-img-left mb-5 mb-lg-0">
                            <h2 className="mb-4">
                                {t('pages.fiamaneClient.featureOne.title')}
                            </h2>
                            <p className="mb-4">
                                {t('pages.fiamaneClient.featureOne.text')}                            </p>
                        </div>
                        <div className='row'>
                            <div className='col-lg-11 col-12'>
                                <div className='accordion faq-accordion' id='accordionExample'>
                                    <div className='accordion-item border border-2 active'>
                                        <h5 className='accordion-header' id='faq-1'>
                                            <button
                                                className='accordion-button'
                                                type='button'
                                                data-bs-toggle='collapse'
                                                data-bs-target='#collapse-1'
                                                aria-expanded='true'
                                            >
                                                {t('pages.fiamaneClient.featureOne.firstQuestion')}                                            </button>
                                        </h5>
                                        <div
                                            id='collapse-1'
                                            className='accordion-collapse collapse show'
                                            aria-labelledby='faq-1'
                                            data-bs-parent='#accordionExample'
                                        >
                                            <div className='accordion-body'>
                                                {t('pages.fiamaneClient.featureOne.firstAnswerPart1')} <a className="fw-bold" href="//fiamane.com/suivi" target="{_blank}">{t('pages.fiamaneClient.featureOne.firstAnswerPart2')}</a> {t('pages.fiamaneClient.featureOne.firstAnswerPart3')} <a className="fw-bold" href="//play.google.com/store/apps/details?id=com.fiamane.client" target="{_blank}">Android</a>{t('pages.fiamaneClient.featureOne.firstAnswerPart4')} <a className='fw-bold' href="//apps.apple.com/app/id1643690653" target="{_blank}">iphone</a>).
                                            </div>
                                        </div>
                                    </div>
                                    <div className='accordion-item border border-2'>
                                        <h5 className='accordion-header' id='faq-2'>
                                            <button
                                                className='accordion-button collapsed'
                                                type='button'
                                                data-bs-toggle='collapse'
                                                data-bs-target='#collapse-2'
                                                aria-expanded='false'
                                            >
                                                {t('pages.fiamaneClient.featureOne.secondQuestion')}
                                            </button>
                                        </h5>
                                        <div
                                            id='collapse-2'
                                            className='accordion-collapse collapse'
                                            aria-labelledby='faq-2'
                                            data-bs-parent='#accordionExample'
                                        >
                                            <div className='accordion-body'>
                                                {t('pages.fiamaneClient.featureOne.secondAnswer')}                                            </div>
                                        </div>
                                    </div>
                                    <div className='accordion-item border border-2'>
                                        <h5 className='accordion-header' id='faq-3'>
                                            <button
                                                className='accordion-button collapsed'
                                                type='button'
                                                data-bs-toggle='collapse'
                                                data-bs-target='#collapse-3'
                                                aria-expanded='false'
                                            >
                                                {t('pages.fiamaneClient.featureOne.thirdQuestion')}
                                            </button>
                                        </h5>
                                        <div
                                            id='collapse-3'
                                            className='accordion-collapse collapse'
                                            aria-labelledby='faq-3'
                                            data-bs-parent='#accordionExample'
                                        >
                                            <div className='accordion-body'>
                                                {t('pages.fiamaneClient.featureOne.thirdAnswer')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

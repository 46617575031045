import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import frFlag from '../imags/flags/fr.png';
import DeFlag from '../imags/flags/de.png';
import EsFlag from '../imags/flags/es.png';
import ItFlag from '../imags/flags/it.png';
import NlFlag from '../imags/flags/nl.png';
import ArFlag from '../imags/flags/ar.png';
import EnFlag from '../imags/flags/uk.png'

export default function LangSwitcher() {
  const { i18n } = useTranslation();
  const lngs = [
    {
      name: 'fr',
      nativeName: 'Français',
      flag: `${frFlag}`,
    },
    {
      name: 'de',
      nativeName: 'Deutsch',
      flag: `${DeFlag}`,
    },
    {
      name: 'es',
      nativeName: 'Español',
      flag: `${EsFlag}`,
    },
    {
      name: 'it',
      nativeName: 'Italiano',
      flag: `${ItFlag}`,
    },
    {
      name: 'nl',
      nativeName: 'Nederlands',
      flag: `${NlFlag}`,
    },
    {
      name: 'ar',
      nativeName: 'العربية',
      flag: `${ArFlag}`,
    },  {
      name: 'en',
      nativeName: 'English',
      flag: `${EnFlag}`,
    },
  ];


  const [selectedLang, setSelectedLang] = useState(
    lngs.find(lng => lng.name === i18n.resolvedLanguage) || lngs[0]
  );
  const [isOpen, setIsOpen] = useState(false);

  const wrapperRef = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <div className='lang_container' ref={wrapperRef}>

      <div className='d-flex justify-content-center align-items-center gap-2 border-2 border border-primary rounded p-2'>
        <img
          className='lang_img'
          src={selectedLang.flag}
          alt='flag'
          onClick={() => setIsOpen(!isOpen)}
        />
        <button className='text-primary bg-transparent border-0'
          onClick={() => setIsOpen(!isOpen)} aria-label="language dropdown menu">
          <i className="fa-solid fa-chevron-down"></i>
        </button>
      </div>
      {isOpen && (
        <div
          className='lang_drop_container'
          style={{
            right:
              localStorage.getItem('i18nextLng') === 'ar' ? '-9rem' : '-1.2rem',
          }}
        >
          {lngs.map((lng, index) => (
            <div key={index}
              className={`lang_drop_item`}
              onClick={() => {
                document.body.style.transform = 'scale(0.95)';
                document.body.style.transition = 'transform 0.25s ease-out';
                setTimeout(() => {
                  i18n.changeLanguage(lng.name);
                  setSelectedLang(lng);
                  document.body.style.transform = 'scale(1)';
                  document.body.style.removeProperty('transform');
                  //window.location.reload();
                  // document.body.style.removeProperty('transition');
                  if (localStorage.getItem('i18nextLng') === 'ar') {
                    document.body.style.direction = 'rtl';
                    document.documentElement.setAttribute('lang', 'ar');
                    localStorage.setItem('direction', 'rtl');
                  } else {
                    document.body.style.direction = 'ltr';
                    document.documentElement.setAttribute('lang', 'fr');
                    localStorage.setItem('direction', 'ltr');
                  }
                }, 170);
                setIsOpen(false);
              }}
            >
              <span>{lng.nativeName}</span>
              <img className='lang_drop_item_img' src={lng.flag} alt='flag' />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

import React, { lazy, Suspense } from "react";
import { useTranslation } from 'react-i18next';
import Layout from "../../layout/Layout";
import PageMeta from "../../components/common/PageMeta";
import Navbar from "../../layout/Header/Navbar";
import HeroHeader from "./HeroHeader";
import FeatureOne from "./FeatureOne";
import FeatureTwo from "./FeatureTwo";
import FeatureThree from "./FeatureThree";
import FeatureFour from "./FeatureFour";
import FeatureFive from "./FeatureFive";
// import CallToAction from "./CallToAction";
const CallToAction = lazy(() => import('./CallToAction'));
const Footer = lazy(() => import('../../layout/Footer/Footer'));
// import Footer from "../../layout/Footer/Footer";

const HomeSassTwo = () => {
  const { t } = useTranslation();
  return (
    <Layout>
      <PageMeta
        title={t("tags.title")}
        description="description"
        appDesciption={t("tags.description")}
        keywords="keywords"
        appKeywords={t("tags.keywords")}
      />
      <Navbar />
      <HeroHeader />
      <FeatureOne />
      <FeatureTwo />
      <FeatureThree />
      <FeatureFour />
      <FeatureFive />
      <CallToAction />


      <Footer footerLight />
    </Layout>
  );
};

export default HomeSassTwo;

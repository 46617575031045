import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import Cookies from 'js-cookie';

// Get the user's preferred locale from the "fiamane_locale" cookie, if present
const langCookie = Cookies.get('fiamane_locale');
const defaultLang = langCookie || 'fr';

i18n.use(Backend)
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: defaultLang,
    debug: true,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;

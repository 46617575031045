import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useTranslation } from 'react-i18next';



const ContactsForm = () => {


  const { t } = useTranslation();

  // declaring variables to get from the user
  const [prospectType, setProspectType] = useState("Agence");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [agencyName, setAgencyName] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState()
  const [preferredWayOfContact, setPreferredWayOfContact] = useState([]);

  // cheking if each input is provided by the user
  const [FirstNameExist, setFirstNameExist] = useState(false);
  const [lastNameExist, setLastNameExist] = useState(false);
  const [agencyNameExist, setAgencyNameExist] = useState(false);
  const [countryExist, setCountryExist] = useState(false);
  const [cityExist, setCityExist] = useState(false);
  const [emailExist, setEmailExist] = useState(false);
  const [phoneExist, setPhoneExist] = useState(false)
  const [preferredWayOfContactExist, setPreferredWayOfContactExist] = useState(false);
  const [privacyPolicyIsChecked, setPrivacyPolicyIsChecked] = useState(false);
  const [first, setfirst] = useState(false)

  // modal after success
  const [modalIsOpen, setModalIsOpen] = useState(false)

  // function to check for each input if exist.
  const InputsValidation = () => {
    if (!firstName) {
      setFirstNameExist(true);
    } else {
      setFirstNameExist(false);
    }

    if (!lastName) {
      setLastNameExist(true)
    } else {
      setLastNameExist(false);
    }

    if (!agencyName) {
      setAgencyNameExist(true)
    } else {
      setAgencyNameExist(false);
    }

    if (!country) {
      setCountryExist(true)
    } else {
      setCountryExist(false)
    }

    if (!city) {
      setCityExist(true)
    } else {
      setCityExist(false);
    }
    if (!email) {
      setEmailExist(true)
    } else {
      setEmailExist(false);
    }

    if (!phone) {
      setPhoneExist(true)
    } else {
      setPhoneExist(false);
    }

    if (preferredWayOfContact.length === 0) {
      setPreferredWayOfContactExist(true);
    } else {
      setPreferredWayOfContactExist(false);
    }

  }
  useEffect(() => {

    if (first) {
      InputsValidation();
    }

  }, [SubmitForm])




  function SubmitForm(e) {
    e.preventDefault();
    setfirst(true);
    const preferredWayOfContactSet = [...new Set(preferredWayOfContact)];

    const prospectData = {
      user_type: prospectType,
      prenom: firstName,
      nom: lastName,
      entreprise: agencyName,
      email: email,
      pays: country,
      ville: city,
      telephone: phone,
      preferred_contact: preferredWayOfContactSet.join()
    }
    if (prospectType && firstName && lastName && agencyName && email && country && city && phone && preferredWayOfContact.length !== 0 && privacyPolicyIsChecked === true) {
      fetch('https://fiamane.com/api/v1/owners/request_demo', {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify(prospectData)
      }).then((response) => response.json()).then((response) => {
        setModalIsOpen(true);

      });
    }

  }

  return (
    <>
      <div className='col-xl-5 col-lg-7 col-md-12 order-0 order-lg-1'>
        <div className='register-wrap p-5 bg-white shadow rounded-custom mt-5 mt-lg-0 mt-xl-0'>
          <h3 className='fw-medium h4'>
            {t('pages.requestADemo.formTitle')}
          </h3>
          {/* modal */}
          {
            modalIsOpen ?
              (<div
                style={{
                  position: 'fixed',
                  left: 0,
                  top: 0,
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'rgba(0,0,0,0.5)',
                  zIndex: '2147483647',
                }}
                onClick={() => {
                  setModalIsOpen(false);
                  window.location.reload();
                }}
              >
                <div
                  className="modal fade"

                ></div>
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content"
                    style={{
                      padding: '16px',
                    }}>
                    <div className="">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          setModalIsOpen(false);
                          window.location.reload();
                        }}
                      >
                        <span className=' text-white h2'>&times;</span>
                      </button>
                    </div>
                    <div className="modal-body d-flex flex-column justify-content-center align-items-center">
                      <h3 className="display-2"> ✔️ </h3>
                      <p className="p-4 text-center h6 text-dark">
                        {t('pages.requestADemo.successMsg1')}
                      </p>
                    </div>
                    <div className="d-flex justify-content-center align-items-center gap-2 p-4">
                      <a href="/" className="btn btn-primary">{t('pages.requestADemo.successMsg2')}</a>
                    </div>
                  </div>
                </div>
              </div>) : ('')}
          <form action='#' onSubmit={SubmitForm} className='mt-4 register-form'>
            <div className='row'>
              <div className='col-sm-12'>
                <p>{t('pages.requestADemo.questionOneLabel')}</p>
                <div className="row mb-30 text-center">
                  <div className="col-12 col-md-6">
                    <label className="radio-card">
                      <input type="radio"
                        name="radio-card"
                        id="green"
                        className='btn-primary'
                        value="Agence"
                        onChange={(e) => setProspectType(e.target.value)}
                        defaultChecked
                      />
                      <div className="card-content-wrapper">
                        <div className="card-content">
                          <i className="fa-solid fa-shop text-primary"></i>
                          <h6>{t('pages.requestADemo.questionOneAnswer')}</h6>
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="col-12 col-md-6 text-center">
                    <label className="radio-card">
                      <input type="radio"
                        name="radio-card"
                        value="Transporteur"
                        onChange={(e) => setProspectType(e.target.value)}
                      />
                      <div className="card-content-wrapper">
                        <div className="card-content ">
                          <i className="fa-solid fa-truck text-primary"></i>
                          <h6>{t('pages.requestADemo.questionOneAnswer2')}</h6>
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              <div className='col-sm-6'>
                <div className='input-group mb-3'>
                  <input
                    type='text'
                    className={`form-control ${FirstNameExist ? "border-danger" : ""} `}
                    placeholder={t('pages.requestADemo.questionTwoLabel')}
                    aria-label='FirstName'
                    onChange={(e) => setFirstName(e.target.value)}

                  />
                </div>
                {FirstNameExist ? <p className='mt-n2 h6 text-danger'>{t('pages.requestADemo.errorMsg')}</p> : ""}
              </div>
              <div className='col-sm-6'>
                <div className='input-group mb-3'>
                  <input
                    type='text'
                    className={`form-control ${lastNameExist ? "border-danger" : ""} `}
                    placeholder={t('pages.requestADemo.questionThreeLabel')}
                    aria-label='LastName'
                    onChange={(e) => setLastName(e.target.value)}

                  />
                </div>
                {lastNameExist ? <p className='mt-n2 h6 text-danger'>{t('pages.requestADemo.errorMsg')}</p> : ""}

              </div>
              <div className='col-sm-12'>
                <div className='input-group mb-3'>
                  <input
                    type='text'
                    className={`form-control ${agencyNameExist ? "border-danger" : ""} `}
                    placeholder={t('pages.requestADemo.questionFourLabel')}
                    aria-label='companyName'
                    onChange={(e) => setAgencyName(e.target.value)}
                  />
                </div>
                {agencyNameExist ? <p className='mt-n2 h6 text-danger'>{t('pages.requestADemo.errorMsg')}</p> : ""}
              </div>
              <div className='col-sm-6'>
                <div className='input-group mb-3'>
                  <select
                    className={`form-control form-select ${countryExist ? "border-danger" : ""} `}
                    name='country'
                    id='country'
                    data-msg='Please select your country.'
                    defaultValue='Default'
                    onChange={(e) => setCountry(e.target.value)}
                  >
                    <option value="">{t('pages.requestADemo.questionFiveLabel')}</option>
                    <option value='Albanie'>{t('pages.requestADemo.countries.albania')}</option>
                    <option value='Algérie'>{t('pages.requestADemo.countries.algeria')}</option>
                    <option value='Allemagne'>{t('pages.requestADemo.countries.germany')}</option>
                    <option value='Andorre'>{t('pages.requestADemo.countries.andorre')}</option>
                    <option value='Angola'>{t('pages.requestADemo.countries.angola')}</option>
                    <option value='Autriche'>{t('pages.requestADemo.countries.austria')}</option>
                    <option value='Bélarus'>{t('pages.requestADemo.countries.belarus')}</option>
                    <option value='Belgique'>{t('pages.requestADemo.countries.belgium')}</option>
                    <option value='Bénin'>{t('pages.requestADemo.countries.benin')}</option>
                    <option value='Bosnie-Herzégovine'>{t('pages.requestADemo.countries.bosniaHerzegovina')}</option>
                    <option value='Botswana'>{t('pages.requestADemo.countries.botswana')}</option>
                    <option value='Bulgarie'>{t('pages.requestADemo.countries.bulgaria')}</option>
                    <option value='Burkina Faso'>{t('pages.requestADemo.countries.burkinaFaso')}</option>
                    <option value='Burundi'>{t('pages.requestADemo.countries.burundi')}</option>
                    <option value='Cabo Verde'>{t('pages.requestADemo.countries.caboVerde')}</option>
                    <option value='Cameroun'>{t('pages.requestADemo.countries.cameroun')}</option>
                    <option value='Comores'>{t('pages.requestADemo.countries.comores')}</option>
                    <option value='Congo'>{t('pages.requestADemo.countries.congo')}</option>
                    <option value='Congo (RDC)'>{t('pages.requestADemo.countries.congoRDC')}</option>
                    <option value="Côte 'Ivoire">{t('pages.requestADemo.countries.ivoryCoast')}</option>
                    <option value='Croatie'>{t('pages.requestADemo.countries.croatia')}</option>
                    <option value='Danemark'>{t('pages.requestADemo.countries.denmark')}</option>
                    <option value='Djibouti'>{t('pages.requestADemo.countries.djibouti')}</option>
                    <option value='Égypte'>{t('pages.requestADemo.countries.egypt')}</option>
                    <option value='Érythrée'>{t('pages.requestADemo.countries.erythree')}</option>
                    <option value='Espagne'>{t('pages.requestADemo.countries.spain')}</option>
                    <option value='Estonie'>{t('pages.requestADemo.countries.estonia')}</option>
                    <option value='Eswatini'>{t('pages.requestADemo.countries.eswatini')}</option>
                    <option value='Éthiopie'>{t('pages.requestADemo.countries.ethiopia')}</option>
                    <option value='Féroé (les Îles)'>{t('pages.requestADemo.countries.feroeIslands')}</option>
                    <option value='Finlande'>{t('pages.requestADemo.countries.finland')}</option>
                    <option value='France'>{t('pages.requestADemo.countries.france')}</option>
                    <option value='Gabon'>{t('pages.requestADemo.countries.gabon')}</option>
                    <option value='Gambie'>{t('pages.requestADemo.countries.gambia')}</option>
                    <option value='Ghana'>{t('pages.requestADemo.countries.ghana')}</option>
                    <option value='Gibraltar'>{t('pages.requestADemo.countries.gibraltar')}</option>
                    <option value='Grèce'>{t('pages.requestADemo.countries.greece')} </option>
                    <option value='Guernesey'>{t('pages.requestADemo.countries.guernsey')}</option>
                    <option value='Guinée'>{t('pages.requestADemo.countries.guinea')}</option>
                    <option value='Guinée équatoriale'>{t('pages.requestADemo.countries.equatorial-guinea')}</option>
                    <option value='Guinée-Bissau'>{t('pages.requestADemo.countries.guinea-bissau')}</option>
                    <option value='Hongrie'>{t('pages.requestADemo.countries.hungary')}</option>
                    <option value='Irlande'>{t('pages.requestADemo.countries.ireland')}</option>
                    <option value='Islande'>{t('pages.requestADemo.countries.iceland')}</option>
                    <option value='Italie'>{t('pages.requestADemo.countries.italy')}</option>
                    <option value='Kenya'>{t('pages.requestADemo.countries.kenya')}</option>
                    <option value='Lesotho'>{t('pages.requestADemo.countries.lesotho')}</option>
                    <option value='Lettonie'>{t('pages.requestADemo.countries.latvia')}</option>
                    <option value='Libéria'>{t('pages.requestADemo.countries.liberia')}</option>
                    <option value='Libye'>{t('pages.requestADemo.countries.libya')}</option>
                    <option value='Liechtenstein'>{t('pages.requestADemo.countries.liechtenstein')}</option>
                    <option value='Lituanie'>{t('pages.requestADemo.countries.lithuania')}</option>
                    <option value='Luxembourg'>{t('pages.requestADemo.countries.luxembourg')}</option>
                    <option value='Macédoine du Nord'>{t('pages.requestADemo.countries.north-macedonia')}</option>
                    <option value='Madagascar'>{t('pages.requestADemo.countries.madagascar')}</option>
                    <option value='Malawi'>{t('pages.requestADemo.countries.malawi')}</option>
                    <option value='Mali'>{t('pages.requestADemo.countries.mali')}</option>
                    <option value='Malte'>{t('pages.requestADemo.countries.malta')}</option>
                    <option value='Maroc'>{t('pages.requestADemo.countries.morocco')}</option>
                    <option value='Maurice'>{t('pages.requestADemo.countries.mauritius')}</option>
                    <option value='Mauritanie'>{t('pages.requestADemo.countries.mauritania')}</option>
                    <option value='Mayotte'>{t('pages.requestADemo.countries.mayotte')}</option>
                    <option value='Moldavie'>{t('pages.requestADemo.countries.moldova')}</option>
                    <option value='Monaco'>{t('pages.requestADemo.countries.monaco')}</option>
                    <option value='Monténégro'>{t('pages.requestADemo.countries.montenegro')}</option>
                    <option value='Mozambique'>{t('pages.requestADemo.countries.mozambique')}</option>
                    <option value='Namibie'>{t('pages.requestADemo.countries.namibia')}</option>
                    <option value='Niger'>{t('pages.requestADemo.countries.niger')}</option>
                    <option value='Nigéria'>{t('pages.requestADemo.countries.nigeria')}</option>
                    <option value='Norvège'>{t('pages.requestADemo.countries.norway')}</option>
                    <option value='Ouganda'>{t('pages.requestADemo.countries.uganda')}</option>
                    <option value='Pays-Bas'>{t('pages.requestADemo.countries.netherlands')}</option>
                    <option value='Pologne'>{t('pages.requestADemo.countries.poland')}</option>
                    <option value='Portugal'>{t('pages.requestADemo.countries.portugal')}</option>
                    <option value='République centrafricaine'>{t('pages.requestADemo.countries.centralAfricanRepublic')}</option>
                    <option value='République démocratique du Congo'>{t('pages.requestADemo.countries.democraticRepublicOfTheCongo')}</option>
                    <option value='République dominicaine'>{t('pages.requestADemo.countries.dominicanRepublic')}</option>
                    <option value='République tchèque'>{t('pages.requestADemo.countries.czechRepublic')}</option>
                    <option value='Roumanie'>{t('pages.requestADemo.countries.romania')}</option>
                    <option value='Royaume-Uni'>{t('pages.requestADemo.countries.unitedKingdom')}</option>
                    <option value='Russie'>{t('pages.requestADemo.countries.russia')}</option>
                    <option value='Rwanda'>{t('pages.requestADemo.countries.rwanda')}</option>
                    <option value='Saint-Marin'>{t('pages.requestADemo.countries.san-marino')}</option>
                    <option value='São Tomé-et-Príncipe'>{t('pages.requestADemo.countries.sao-tome-and-principe')}</option>
                    <option value='Sénégal'>{t('pages.requestADemo.countries.senegal')}</option>
                    <option value='Serbie'>{t('pages.requestADemo.countries.serbia')}</option>
                    <option value='Seychelles'>{t('pages.requestADemo.countries.seychelles')}</option>
                    <option value='Sierra Leone'>{t('pages.requestADemo.countries.sierra-leone')}</option>
                    <option value='Slovaquie'>{t('pages.requestADemo.countries.slovakia')}</option>
                    <option value='Slovénie'>{t('pages.requestADemo.countries.slovenia')}</option>
                    <option value='Somalie'>{t('pages.requestADemo.countries.somalia')}</option>
                    <option value='Soudan'>{t('pages.requestADemo.countries.sudan')}</option>
                    <option value='Suède'>{t('pages.requestADemo.countries.sweden')}</option>
                    <option value='Suisse'>{t('pages.requestADemo.countries.switzerland')}</option>
                    <option value='Suriname'>{t('pages.requestADemo.countries.suriname')}</option>
                    <option value='Tadjikistan'>{t('pages.requestADemo.countries.tajikistan')}</option>
                    <option value='Tanzanie'>{t('pages.requestADemo.countries.tanzania')}</option>
                    <option value='Tchad'>{t('pages.requestADemo.countries.chad')}</option>
                    <option value='Timor-Leste'>{t('pages.requestADemo.countries.timor-leste')}</option>
                    <option value='Togo'>{t('pages.requestADemo.countries.togo')}</option>
                    <option value='Tonga'>{t('pages.requestADemo.countries.tonga')}</option>
                    <option value='Tunisie'>{t('pages.requestADemo.countries.tunisia')}</option>
                    <option value='Turkie'>{t('pages.requestADemo.countries.turky')}</option>
                    <option value='Ukraine'>{t('pages.requestADemo.countries.ukraine')}</option>
                    <option value='Vanuatu'>{t('pages.requestADemo.countries.vanuatu')}</option>
                    <option value='Vatican'>{t('pages.requestADemo.countries.vatican')}</option>
                    <option value='Yémen'>{t('pages.requestADemo.countries.yemen')}</option>
                    <option value='Zambie'>{t('pages.requestADemo.countries.zambia')}</option>
                    <option value='Zimbabwe'>{t('pages.requestADemo.countries.zimbabwe')}</option>
                    <option value='autre'>{t('pages.requestADemo.countries.other')}</option>


                  </select>
                </div>
                {countryExist ? <p className='mt-n2 h6 text-danger'>{t('pages.requestADemo.errorMsg')}</p> : ""}
              </div>
              <div className='col-sm-6'>
                <div className='input-group mb-3'>
                  <input
                    type='text'
                    className={`form-control ${cityExist ? "border-danger" : ""} `}
                    placeholder={t('pages.requestADemo.questionSixLabel')}
                    aria-label='city'
                    onChange={(e) => setCity(e.target.value)}
                  />
                </div>
                {cityExist ? <p className='mt-n2 h6 text-danger'>{t('pages.requestADemo.errorMsg')}</p> : ""}
              </div>
              <div className='col-sm-12 '>
                <div className='input-group mb-3'>
                  <input
                    type='email'
                    className={`form-control ${emailExist ? "border-danger" : ""} `}
                    placeholder={t('pages.requestADemo.questionSevenLabel')}
                    aria-label='email'
                    onChange={(e) => setEmail(e.target.value)}
                  // required
                  />
                </div>
                {emailExist ? <p className='mt-n2 h6 text-danger'>{t('pages.requestADemo.errorMsg')}</p> : ""}
              </div>
              <div className='col-sm-12 forcedirectionLeft'>
                <div className='input-group mb-3'>

                  <PhoneInput
                    containerClass={` mb-3 rounded border border-2 ${phoneExist ? "border-danger" : ""} `}
                    inputClass={'pt-4 pb-4 w-100'}
                    country={'ma'}
                    preferredCountries={['ma', 'fr', 'es', 'it', 'nl', 'de', 'gb', 'be']}
                    // value={phone}

                    onChange={setPhone}
                  />
                </div>
                {phoneExist ? <p className='mt-n4 h6 text-danger'>{t('pages.requestADemo.errorMsg')}</p> : ""}

              </div>
              <div className='col-12'>
                <p>{t('pages.requestADemo.questionNineLabel')}</p>
                <div className='form-check mt-n3'>
                  <input className="form-check-input"
                    type="checkbox"
                    value="whatsapp"
                    onChange={(e) => setPreferredWayOfContact((current) => (
                      [...current, e.target.value]
                    ))}
                  />
                  <label className="form-check-label" >
                    Whatsapp
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input"
                    type="checkbox"
                    value="telephone"
                    onChange={(e) => setPreferredWayOfContact((current) => (
                      [...current, e.target.value]
                    ))} />
                  <label className="form-check-label" >
                    {t('pages.requestADemo.questionNineAnswer2')}
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input"
                    type="checkbox"
                    value="email"
                    onChange={(e) => setPreferredWayOfContact((current) => (
                      [...current, e.target.value]
                    ))} />
                  <label className="form-check-label" >
                    {t('pages.requestADemo.questionSevenLabel')}
                  </label>
                </div>
                {preferredWayOfContactExist ? <p className='mt-n2 h6 text-danger'>{t('pages.requestADemo.errorMsg')}</p> : ""}
              </div>
              <div className='col-12 mt-4'>
                <div className='form-check'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    onChange={() => setPrivacyPolicyIsChecked(!privacyPolicyIsChecked)}

                  />
                  <label
                    className='form-check-label small'
                  >
                    {t('pages.requestADemo.politiqueConfidentialiteMsg1')}
                    <Link to='//fiamane-website.vercel.app/pdc' target='_blank'>  {t('pages.requestADemo.politiqueConfidentialiteMsg2')}</Link>
                  </label>
                </div>
                {!privacyPolicyIsChecked ? <p className='h6 text-danger'>{t('pages.requestADemo.errorMsg')}</p> : ""}
              </div>

              <div className='col-12'>
                <button
                  type='submit'
                  className='btn btn-primary mt-4 d-block w-100'
                >
                  {t('pages.requestADemo.send')}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ContactsForm;

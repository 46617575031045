import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './i18n';
import LoadingSpinner from './components/others/LoadingSpinner';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.Suspense fallback= {<LoadingSpinner />}>
    <App />
  </React.Suspense>

);

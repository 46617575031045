import React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'

const PriceOne = ({ paddingTop, haspriceTitleWithHeader }) => {

  const { t } = useTranslation();
  const [priceWithCurrency, setPriceWithCurrency] = useState({
    basic: "9.99 €",
    smart: "19.99 €",
    premium: "29.99 €"

  });
  const changeCurrencyToMad = () => {
    setPriceWithCurrency(current => (
      {
        ...current,
        basic: "99 DH",
        smart: "199 DH",
        premium: "299 DH"
      }));
  };
  const changeCurrencyToEuro = () => {
    setPriceWithCurrency(current => (
      {
        ...current,
        basic: "9.99 €",
        smart: "19.99 €",
        premium: "29.99 €"
      }));
  };

  const [isOn, setIsOn] = useState(false);
  useEffect(() => {
    if (isOn) {
      changeCurrencyToMad();
    } else {
      changeCurrencyToEuro();
    }
    return () => {
    }
  }, [isOn]);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const handleResize = () => setScreenWidth(window.innerWidth);
  useEffect(() => {
    window.addEventListener('resize', handleResize);
  }, []);

  const [currentIndex, setCurrentIndex] = useState(1);
  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + 3) % 3);
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % 3);
  };

  return (
    <>
      {screenWidth < 767
        ?
        <section
          className={`pricing-section ${paddingTop} position-relative z-2`}
        >
          <div className='container'>
            <div className="nav nav-pills mb-4 pricing-tab-list d-flex flex-row justify-content-center">
              <div className="d-flex align-items-center justify-content-center">
                <span className="h4" style={{ "margin": "0.8em" }}>{t('pages.pricing.pricingPlans.euro')}</span>
                <label className="switch">
                  <input type="checkbox" checked={isOn} onChange={() => setIsOn(!isOn)} />
                  <span className="slider round"></span>
                </label>
                <span className="h4" style={{ "margin": "0.8em" }}>{t('pages.pricing.pricingPlans.dirham')}</span></div>
            </div>
            <div className='d-flex justify-content-center align-items-center'>
              <div className={`col-12 d-flex align-items-stretch slide slide1 ${currentIndex === 0 ? "active" : "d-none"}`}
                style={{ minHeight: "660px" }}>
                <div
                  className='
                position-relative
                single-pricing-wrap
                rounded-custom
                bg-white
                custom-shadow
                p-5
                mb-4 mb-lg-0
              '
                >
                  <div className='pricing-header mb-32'>
                    <h3 className='text-primary d-block'>{t('pages.pricing.pricingPlans.basicPlan.planName')}</h3>
                    <h6 className='fw-light'>{t('pages.pricing.pricingPlans.basicPlan.planDesc')}</h6>
                    {/* <h4 className='display-6 fw-semi-bold'>
                      {t('pages.pricing.pricingPlans.basicPlan.planPrice')}
                    </h4> */}
                    <h4 className='display-6 fw-semi-bold'>
                      {priceWithCurrency.basic}<span>{t('pages.pricing.pricingPlans.smartPlan.paymentFrequency')}</span>
                    </h4>
                  </div>
                  <div className='pricing-info mb-4'>
                    <ul className='pricing-feature-list list-unstyled'
                      style={{
                        padding: 'initial'
                      }}>
                      <li>{t('pages.pricing.pricingPlans.basicPlan.planFeatures.featureZero0')}</li>
                      <li>{t('pages.pricing.pricingPlans.basicPlan.planFeatures.featureTwo')}</li>
                      <li>{t('pages.pricing.pricingPlans.basicPlan.planFeatures.featureThree')}</li>
                      <li>{t('pages.pricing.pricingPlans.basicPlan.planFeatures.featureFour')}</li>
                      <li>{t('pages.pricing.pricingPlans.basicPlan.planFeatures.featureFive')}</li>

                    </ul>
                  </div>
                  <Link
                    to='/request-demo'
                    className='btn btn-outline-primary mt-2'
                  >
                    {t('pages.pricing.pricingPlans.smartPlan.button')}
                  </Link>
                </div>
              </div>
              <div className={`col-12 d-flex align-items-stretch slide slide2 ${currentIndex === 2 ? "active" : "d-none"}`}
                style={{ minHeight: "660px" }}>
                <div
                  className='
                position-relative
                single-pricing-wrap
                rounded-custom
                bg-dark
                text-white
                p-5
                mb-4 mb-lg-0
                min-h-20
                d-flex flex-column
              '
                >
                  <div className='pricing-header mb-32'>
                    <h3 className='text-primary d-block'>
                      {t('pages.pricing.pricingPlans.smartPlan.planName')}
                    </h3>
                    <h6 className='fw-light'>{t('pages.pricing.pricingPlans.smartPlan.planDesc')}</h6>
                    <h4 className='display-6 fw-semi-bold'>

                      {priceWithCurrency.smart}<span>{t('pages.pricing.pricingPlans.smartPlan.paymentFrequency')}</span>
                    </h4>
                  </div>
                  <div className='pricing-info mb-4'>
                    <ul className='pricing-feature-list list-unstyled'
                      style={{
                        padding: 'initial'
                      }}>
                      <li className='h4'>{t('pages.pricing.pricingPlans.smartPlan.planFeatures.featureZero')}</li>
                      <li>{t('pages.pricing.pricingPlans.smartPlan.planFeatures.featureOne')}</li>
                      <li>{t('pages.pricing.pricingPlans.smartPlan.planFeatures.featureTwo')}</li>
                      <li>{t('pages.pricing.pricingPlans.smartPlan.planFeatures.featureThree')}</li>
                      <li>{t('pages.pricing.pricingPlans.smartPlan.planFeatures.featureFour')}</li>
                    </ul>
                  </div>
                  <Link to='/request-demo' className='btn btn-primary mt-auto align-self-start'>
                    {t('pages.pricing.pricingPlans.smartPlan.button')}
                  </Link>
                </div>
              </div>
              <div className={`col-12 d-flex align-items-stretch slide slide3 ${currentIndex === 1 ? "active" : "d-none"}`}
                style={{ minHeight: "660px" }}>
                <div
                  className='
                position-relative
                single-pricing-wrap
                rounded-custom
                bg-white
                custom-shadow
                p-5
                mb-4 mb-lg-0
                d-flex flex-column 
               '
                >
                  <div className='pricing-header mb-32'>
                    <h3 className='text-primary d-block'>{t('pages.pricing.pricingPlans.premiumPlan.planName')}</h3>
                    <h6 className='fw-light'>{t('pages.pricing.pricingPlans.premiumPlan.planDesc')}</h6>
                    <h4 className='display-6 fw-semi-bold'>
                      {priceWithCurrency.premium}<span>{t('pages.pricing.pricingPlans.premiumPlan.paymentFrequency')}</span>
                    </h4>
                  </div>
                  <div className='mb-4 '>
                    <ul className='pricing-feature-list list-unstyled'
                      style={{
                        padding: 'initial'
                      }}>
                      <li className='h4'>{t('pages.pricing.pricingPlans.premiumPlan.planFeatures.featureZero')}</li>
                      <li>{t('pages.pricing.pricingPlans.premiumPlan.planFeatures.featureOne')}</li>
                      <li>{t('pages.pricing.pricingPlans.premiumPlan.planFeatures.featureTwo')}</li>
                      <li>{t('pages.pricing.pricingPlans.premiumPlan.planFeatures.featureThree')}</li>
                    </ul>
                  </div>
                  <Link
                    to='/request-demo'
                    className='btn btn-outline-primary mt-auto align-self-start'
                  >
                    {t('pages.pricing.pricingPlans.premiumPlan.button')}
                  </Link>
                </div>

              </div>
              <div className='z-12 w-100 position-absolute d-flex justify-content-between swiper-nav-control'>
                <button onClick={handleNext} className='swiper-button-next'>&lt;</button>
                <button onClick={handlePrev} className='swiper-button-prev'>&gt;</button>
              </div>
            </div>
          </div>
        </section>

        :

        <section
          className={`pricing-section ${paddingTop} position-relative z-2`}
        >
          <div className='container'>
            <div className="nav nav-pills mb-4 pricing-tab-list d-flex flex-row justify-content-center">
              <div className="d-flex align-items-center justify-content-center">
                <span className="h3" style={{ "margin": "0.8em" }}>{t('pages.pricing.pricingPlans.euro')}</span>
                <label className="switch">
                  <input type="checkbox" checked={isOn} onChange={() => setIsOn(!isOn)} />
                  <span className="slider round"></span>
                </label>
                <span className="h3" style={{ "margin": "0.8em" }}>{t('pages.pricing.pricingPlans.dirham')}</span>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-4 col-md-4 d-flex align-items-stretch'>
                <div
                  className='
                  position-relative
                  single-pricing-wrap
                  rounded-custom
                  bg-white
                  custom-shadow
                  p-5
                  mb-4 mb-lg-0
                '
                >
                  <div className='pricing-header mb-32'>
                    <h3 className='text-primary d-block'>{t('pages.pricing.pricingPlans.basicPlan.planName')}</h3>
                    <h6 className='fw-light'>{t('pages.pricing.pricingPlans.basicPlan.planDesc')}</h6>
                    <h4 className='display-6 fw-semi-bold'>
                      {priceWithCurrency.basic}<span>{t('pages.pricing.pricingPlans.smartPlan.paymentFrequency')}</span>
                    </h4>
                  </div>
                  <div className='pricing-info mb-4'>
                    <ul className='pricing-feature-list list-unstyled'
                      style={{
                        padding: 'initial'
                      }}>
                      <li>{t('pages.pricing.pricingPlans.basicPlan.planFeatures.featureOne')}</li>
                      <li>{t('pages.pricing.pricingPlans.basicPlan.planFeatures.featureTwo')}</li>
                      <li>{t('pages.pricing.pricingPlans.basicPlan.planFeatures.featureThree')}</li>
                      <li>{t('pages.pricing.pricingPlans.basicPlan.planFeatures.featureFour')}</li>
                      <li>{t('pages.pricing.pricingPlans.basicPlan.planFeatures.featureFive')}</li>

                    </ul>
                  </div>
                  <Link to='/request-demo' className='btn btn-outline-primary mt-2'>
                    {t('pages.pricing.pricingPlans.basicPlan.button')}
                  </Link>

                  {/* <!--pattern start--> */}
                  <div
                    className='
                    dot-shape-bg
                    position-absolute
                    z--1
                    left--40
                    bottom--40
                  '
                  >
                    <img src='assets/img/shape/dot-big-square.svg' alt='shape' />
                  </div>
                  {/* <!--pattern end--> */}
                </div>
              </div>
              <div className='col-lg-4 col-md-4 d-flex align-items-stretch'>
                <div
                  className='
                  position-relative
                  single-pricing-wrap
                  rounded-custom
                  bg-dark
                  text-white
                  p-5
                  mb-4 mb-lg-0
                  min-h-20
                  d-flex flex-column
                '
                >
                  <div className='pricing-header mb-32'>
                    <h3 className='text-primary d-block'>
                      {t('pages.pricing.pricingPlans.smartPlan.planName')}
                    </h3>
                    <h6 className='fw-light'>{t('pages.pricing.pricingPlans.smartPlan.planDesc')}</h6>
                    <h4 className='display-6 fw-semi-bold'>

                      {priceWithCurrency.smart}<span>{t('pages.pricing.pricingPlans.smartPlan.paymentFrequency')}</span>
                    </h4>
                  </div>
                  <div className='pricing-info mb-4'>
                    <ul className='pricing-feature-list list-unstyled' style={{
                      padding: 'initial'
                    }}>
                      <li className='h4'>{t('pages.pricing.pricingPlans.smartPlan.planFeatures.featureZero')}</li>
                      <li>{t('pages.pricing.pricingPlans.smartPlan.planFeatures.featureOne')}</li>
                      <li>{t('pages.pricing.pricingPlans.smartPlan.planFeatures.featureTwo')}</li>
                      <li>{t('pages.pricing.pricingPlans.smartPlan.planFeatures.featureThree')}</li>
                      <li>{t('pages.pricing.pricingPlans.smartPlan.planFeatures.featureFour')}</li>
                    </ul>
                  </div>
                  <Link to='/request-demo' className='btn btn-primary mt-auto align-self-start'>
                    {t('pages.pricing.pricingPlans.smartPlan.button')}
                  </Link>
                </div>
              </div>
              <div className='col-lg-4 col-md-4 d-flex align-items-stretch'>
                <div
                  className='
                  position-relative
                  single-pricing-wrap
                  rounded-custom
                  bg-white
                  custom-shadow
                  p-5
                  mb-4 mb-lg-0
                  d-flex flex-column
                 '
                >
                  <div className='pricing-header mb-32'>
                    <h3 className='text-primary d-block'>{t('pages.pricing.pricingPlans.premiumPlan.planName')}</h3>
                    <h6 className='fw-light'>{t('pages.pricing.pricingPlans.premiumPlan.planDesc')}</h6>
                    <h4 className='display-6 fw-semi-bold'>
                      {priceWithCurrency.premium}<span>{t('pages.pricing.pricingPlans.premiumPlan.paymentFrequency')}</span>
                    </h4>
                  </div>
                  <div className='pricing-info mb-4'>
                    <ul className='pricing-feature-list list-unstyled' style={{
                      padding: 'initial'
                    }}>
                      <li className='h4'>{t('pages.pricing.pricingPlans.premiumPlan.planFeatures.featureZero')}</li>
                      <li>{t('pages.pricing.pricingPlans.premiumPlan.planFeatures.featureOne')}
                      </li>
                      <li>{t('pages.pricing.pricingPlans.premiumPlan.planFeatures.featureTwo')}</li>
                      <li>{t('pages.pricing.pricingPlans.premiumPlan.planFeatures.featureThree')}</li>
                    </ul>
                  </div>
                  <Link
                    to='/request-demo'
                    className='btn btn-outline-primary mt-auto align-self-start'
                  >
                    {t('pages.pricing.pricingPlans.premiumPlan.button')}
                  </Link>

                  {/* <!--pattern start--> */}
                  <div className='dot-shape-bg position-absolute z--1 right--40 top--40'>
                    <img src='assets/img/shape/dot-big-square.svg' alt='shape' />
                  </div>
                  {/* <!--pattern end--> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      }
    </>
  );
};

export default PriceOne;

import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import OffCanvasMenu from './OffCanvasMenu';
import { useTranslation } from 'react-i18next';
import LanguageDropdown from '../../components/LanguageDropdown'
import LogoFiamane from '../../imags/logo/fiamaneLogo.svg';
import LogoFiamaneWhite from '../../imags/logo/fiamaneLogoWhite.svg';
import { motion } from 'framer-motion'


const Navbar = ({ navDark }) => {
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);

  useEffect(() => {
    const stickyheader = document.querySelector('.main-header');
    setHeaderTop(stickyheader.offsetTop);
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  const { t } = useTranslation();

  return (

    <>
      <header
        className={`main-header ${navDark ? 'position-absolute ' : ''
          } w-100 position-absolute `}
      >

        <nav
          className={`navbar navbar-expand-xl z-10 ${navDark ? 'navbar-dark' : 'navbar-light'
            } sticky-header ${scroll > headerTop ? 'affix' : ''}`}
        >
          <div className="container d-flex align-items-center justify-content-lg-between position-relative">
            <NavLink
              to="/"
              className="navbar-brand d-flex align-items-center mb-md-0 text-decoration-none"
            >
              <img
                src={LogoFiamaneWhite}
                alt="logo"
                className="img-fluid logo-white"
                style={{
                  width: "7rem"
                }}
              />
              <img
                src={LogoFiamane}
                alt="logo"
                className="img-fluid logo-color"
                style={{
                  width: "7rem"
                }}
              />
            </NavLink>

            <div className="collapse navbar-collapse justify-content-lg-between">
              <ul className="nav col-12 col-md-auto justify-content-center main-menu">
                <li>
                  <NavLink to="/pro" className={({ isActive }) =>
                    (isActive ? "nav-link nav-link-active" : "nav-link")}>
                    {t('navbar.page_2')}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/client" className={({ isActive }) => (isActive ? "nav-link nav-link-active" : "nav-link")}>
                    {t('navbar.page_4')}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/pricing" className={({ isActive }) =>
                    (isActive ? "nav-link nav-link-active" : "nav-link")}>
                    {t('navbar.page_3')}
                  </NavLink>
                </li>

                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="/#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {t('navbar.plus')}
                  </a>
                  <div className="dropdown-menu border-0 rounded-custom shadow py-0 bg-white">
                    <div className="dropdown-grid rounded-custom">
                      <div className="dropdown-grid-item">
                        <NavLink to="/contact-us" className="dropdown-link px-0">
                          <span className="me-2">
                            <i className="far fa-address-book"></i>
                          </span>
                          <div className="drop-title">
                            {t('navbar.page_5')}
                          </div>
                        </NavLink>
                        <NavLink to="/help-center" className="dropdown-link">
                          <span className="me-2">
                            <i className="far fa-life-ring"></i>
                          </span>
                          <div className="drop-title">
                            {t('navbar.page_6')}
                          </div>
                        </NavLink>
                        <NavLink to="/request-demo" className="dropdown-link">
                          <span className="me-2">
                            <i className="far fa-laptop-code"></i>
                          </span>
                          <div className="drop-title">
                            {t('navbar.page_7')}
                          </div>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <div className='d-flex justify-content-center align-items-center' >
                <a href="https://platforme.fiamane.com/"
                  className="btn btn-platform"
                  target='_blank'
                  rel="noreferrer"
                >
                  {t('navbar.page_1')}
                </a>

                <motion.button
                  className="btn"

                  animate={{
                    scale: [1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1.2, 1, 1],
                    rotate: ['0deg', '0deg', '-15deg', '0deg', '15deg', '-15deg', '0deg', '15deg', '-15deg', '0deg', '15deg', '-15deg', '0deg', '15deg', '-15deg', '0deg', '15deg', '0deg'],
                  }}
                  transition={{
                    duration: 2,
                    loop: Infinity,
                    ease: "easeInOut",
                  }}
                >
                  <NavLink to="/tracking" className={({ isActive }) =>
                    (isActive ? "btn btn-primary" : "btn btn-primary")}>
                    {t('navbar.page_8')}
                  </NavLink>
                </motion.button>
              </div>
            </div>
            <LanguageDropdown />
            <NavLink
              className="navbar-toggler border-0"
              to="#offcanvasWithBackdrop"
              role="button"
            >
              <span
                className="far fa-bars display-6"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasWithBackdrop"
                aria-controls="offcanvasWithBackdrop"
              ></span>
            </NavLink>
            <OffCanvasMenu />
          </div>
        </nav>
      </header>
    </>
  );
};

export default Navbar;

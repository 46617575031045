import React from 'react';
const PageHeader = ({ title, desc }) => {
  return (
    <>
      <section
        className='page-header position-relative overflow-hidden pt-100 pb-60 bg-dark'
        // style={{
        //   background:
        //     "url('assets/img/page-header-bg.svg')no-repeat bottom left",
        // }}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-lg-8 col-md-12'>
              <h1 className='display-5'>{title}</h1>
              <p className='lead'>{desc}</p>
            </div>
          </div>
        </div>
      </section>

    </>
  );
};

export default PageHeader;
